import { ref, watch, computed } from '@vue/composition-api'
// import moment from 'moment'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useUserSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  // const {
  //   useHttpCodeAlert,
  // } = useAlert()

  const statusOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const departmentsOptions = ref([])

  const getMetaListData = () => store.dispatch('api/getMetaList')

  const ui = {
    statusList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
    onlineStatus: [{
      online: '在線', busy: '忙線', offline: '離線',
    }, {
      online: 'dot-green', busy: 'dot-yellow', offline: 'dot-gray',
    }],
  }

  return {
    ui,
    statusOptions,
    departmentsOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useUserList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  sortBy.value = 'last_action_time'

  const isBusy = ref(false)
  const searchStatus = ref(true)
  const searchDepartment = ref(null)
  const timeRange = ref(null)
  const isTableBusy = ref(false)
  const tableData = ref([])
  const departmentsOptions = ref([])
  const totalSetting = ref({
    isSet: false,
    total: 0,
  })

  const blankUserData = {
    id: null,
    status: false,
    name: null,
    account: null,
    image: null,
    folder_id: null,
    department_id: null,
    online_status: 'offline',
    last_login_ip: null,
    last_action_time: null,
    created_at: null,
    updated_at: null,
  }

  const blankDepartmentInfo = {
    id: null,
    name: null,
    description: null,
  }

  const dataMeta = computed(() => {
    const localItemsCount = tableData.value ? tableData.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNum.value,
    }
  })

  const setUserDelete = (...arg) => store.dispatch('admin-user/setUserDelete', ...arg)
  const setUserCreate = (...arg) => store.dispatch('admin-user/setUserCreate', ...arg)
  const setUserUpdate = (...arg) => store.dispatch('admin-user/setUserUpdate', ...arg)

  const getMetaListData = () => {
    store.dispatch('api/getMetaList', { key: 'departments' })
      .then(response => {
        const { departments } = response.data.data
        departmentsOptions.value = departments
      })
  }

  const getUserListData = () => {
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }
    // 整理filters
    let resolveFilters = ''

    if (searchStatus.value !== null) {
      resolveFilters = `status: ${searchStatus.value ? 1 : 0}`
    }

    if (searchDepartment.value !== null) {
      resolveFilters = `department_id: ${searchDepartment.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    isTableBusy.value = true

    store.dispatch('admin-user/getUserList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: resolveTimeRange,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const { userData } = store.state.app
        tableData.value = data.map(item => {
          const resolve = {
            ...syncObject(blankUserData, item),
            department_info: syncObject(blankDepartmentInfo, item.department_id ? item.department_info : {}),
          }
          if (userData && userData.id === item.id) resolve.online_status = 'online'
          return resolve
        })
        totalNum.value = total
        if (!totalSetting.value.isSet) {
          totalSetting.value.total = total
          totalSetting.value.isSet = true
        }
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchData = () => getUserListData()

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchStatus, timeRange, searchDepartment], () => {
    refetchData()
  })

  getMetaListData()

  return {
    isBusy,
    refDataListTable,
    tableData,
    isTableBusy,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchStatus,
    timeRange,
    departmentsOptions,
    searchDepartment,
    totalSetting,
    blankUserData,
    refetchData,
    refetchTable,
    getMetaListData,

    setUserDelete,
    setUserCreate,
    setUserUpdate,
    getUserListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useUserViewGeneral = () => {
  const {
    syncObject,
    // syncObjectDeep,
    // refonlineTime,
    // onlineTime,
    // updateOnline,
  } = useCommenSettings()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    blankUserData,
  } = useUserList()

  const generalData = ref(null)

  const blankInformationData = {
    id: null,
    birthday: null,
    contact_mail: null,
    emergency_name: null,
    emergency_phone: null,
    job_title: null,
    updated_at: null,
    user_id: null,
  }

  const getUserInfoData = (...args) => {
    store.commit('admin-user/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('admin-user/getUserData', ...args)
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankUserData, data),
          information: syncObject(blankInformationData, data.information ? data.information : {}),
        }
        // generalData.value = resolveData
        store.commit('admin-user/UPDATE_USER_INFO_STATE', resolveData)
        store.commit('admin-user/UPDATE_BUSY_LOADING_STATE', false)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const setUserUpdate = (...args) => store.dispatch('admin-user/setUserUpdate', ...args)
  const setUserImageUpload = (...args) => store.dispatch('admin-user/setUserImageUpload', ...args)

  return {
    getUserInfoData,
    setUserUpdate,
    setUserImageUpload,
    generalData,
  }
}

// export const useUserViewWorkPhase = () => {
//   const toast = useToast()
//   const refWorkphaseListTable = ref(null)
//   const refonlineTime = ref(0)

//   const perPage = ref(10)
//   const totalNum = ref(0)
//   const currentPage = ref(1)
//   const perPageOptions = [10, 20, 50, 100]
//   const searchQuery = ref('')
//   const sortBy = ref(null)
//   const isSortDirDesc = ref(false)
//   const workStateFilter = ref({ label: '工作階段', value: 1 })
//   const timeRange = ref(null)
//   const tableColumns = ref([
//     {
//       label: '編號', key: 'id', sortable: true, searchable: false, select: true,
//     },
//     {
//       label: '裝置', key: 'isPhone', sortable: true, searchable: true, select: true,
//     },
//     {
//       label: 'IP位置', key: 'ip', sortable: true, searchable: true, select: true,
//     },
//     {
//       label: '位置', key: 'location', sortable: true, searchable: true, select: true,
//     },
//     {
//       label: '作業系統', key: 'os', sortable: true, searchable: true, select: true,
//     },
//     {
//       label: '瀏覽器', key: 'browser', sortable: true, searchable: true, select: true,
//     },
//     {
//       label: '登入時間', key: 'login_time', sortable: true, searchable: true, select: true,
//     },
//     {
//       label: '狀態', key: 'state', searchable: true, select: true,
//     },
//   ])

//   const workStateOptions = [
//     { label: '工作階段', value: 1 },
//     { label: '歷史紀錄', value: 0 },
//   ]

//   const onlineTime = () => {
//     refonlineTime.value += 1
//   }

//   const updateOnline = time => {
//     const createTime = Math.floor(new Date(time).getTime() / 1000)
//     const today = Math.floor(new Date().getTime() / 1000)
//     const diffTime = today - createTime
//     if (diffTime < 0) return '時間錯誤'
//     // 定義單位時間
//     const second = 1
//     const minute = second * 60
//     const hour = minute * 60

//     // 定義換算時間
//     let calcTime
//     const secondCalc = parseInt(diffTime / second, 10)
//     const minuteCalc = parseInt(diffTime / minute, 10)
//     const hourCalc = parseInt(diffTime / hour, 10)

//     if (hourCalc >= 1 && hourCalc <= 23) {
//       calcTime = `${hourCalc} 小時前`
//     } else if (minuteCalc >= 1 && minuteCalc <= 59) {
//       calcTime = `${minuteCalc} 分鐘前`
//     } else if (secondCalc >= 1 && secondCalc <= 59) {
//       calcTime = `${secondCalc} 秒前`
//     } else {
//       calcTime = moment(time).format('YYYY-MM-DD')
//     }
//     return calcTime
//   }

//   const dataMeta = computed(() => {
//     const localItemsCount = refWorkphaseListTable.value ? refWorkphaseListTable.value.localItems.length : 0
//     return {
//       from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
//       to: perPage.value * (currentPage.value - 1) + localItemsCount,
//       of: totalNum.value,
//     }
//   })

//   const refetchData = () => {
//     refonlineTime.value = 0
//     refWorkphaseListTable.value.refresh()
//   }

//   watch([workStateFilter, searchQuery, perPage, currentPage, timeRange], () => {
//     refetchData()
//   })

//   const setWorkphaseDelete = (...arg) => store.dispatch('admin-user/setWorkphaseDelete', ...arg)
//   const setWorkphaseAllDelete = (...arg) => store.dispatch('admin-user/setWorkphaseAllDelete', ...arg)

//   const getWorkphaseData = (ctx, callback) => {
//     store
//       .dispatch('admin-user/getWorkphase', {
//         id: router.currentRoute.params.id,
//         data: {
//           search: searchQuery.value,
//           limit: perPage.value,
//           page: currentPage.value,
//           sort: sortBy.value,
//           order: isSortDirDesc.value === true ? 'asc' : 'desc',
//           offset: dataMeta.value.from,
//           range: timeRange.value,
//           filters: workStateFilter.value ? `state:${workStateFilter.value.value}` : null,
//           _: Date.now(),
//         },
//       })
//       .then(response => {
//         if (response.data.success !== true) {
//           toast({
//             component: ToastificationContent,
//             props: {
//               title: response.data.message,
//               icon: 'AlertTriangleIcon',
//               variant: 'danger',
//             },
//           })
//           return
//         }
//         const { data, total } = response.data.data
//         callback(data)
//         totalNum.value = total
//       })
//       .catch(() => {
//         toast({
//           component: ToastificationContent,
//           props: {
//             title: '提取使用者列表時出錯',
//             icon: 'AlertTriangleIcon',
//             variant: 'danger',
//           },
//         })
//       })
//   }

//   return {
//     getWorkphaseData,
//     setWorkphaseDelete,
//     setWorkphaseAllDelete,
//     refetchData,
//     onlineTime,
//     updateOnline,
//     timeRange,
//     refonlineTime,
//     tableColumns,
//     perPage,
//     currentPage,
//     totalNum,
//     dataMeta,
//     perPageOptions,
//     searchQuery,
//     sortBy,
//     isSortDirDesc,
//     refWorkphaseListTable,

//     workStateFilter,
//     workStateOptions,
//   }
// }
