<template>
  <div style="height: inherit">
    <!-- 更新使用者 -->
    <update-modal
      v-if="selected"
      ref="updateModal"
      :user-data-item="selected"
      @refetch-data="refetchTable"
    />

    <!-- ECommerce Header -->
    <section class="mb-1">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler cursor-pointer">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <!-- <div class="search-results">
                totalProducts results found
              </div> -->
            </div>
            <div class="view-options d-flex align-items-center">
              <!-- // justify-content-between w-100 -->

              <toggle-button
                v-model="searchStatus"
                :color="{checked: '#20c997', unchecked: '#8f8f8f'}"
                :switch-color="{checked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                :width="60"
                :height="24"
                :labels="{checked: '啟用', unchecked: '停用'}"
                :sync="true"
                class="mt-50"
              />

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn ml-50"
                @click="onSubmitUpdate(null)"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!--  員工列表 -->
    <div class="user-list">
      <div v-if="!isTableBusy">
        <b-row class="animate__animated animate__fadeIn">
          <b-col
            v-for="user in tableData"
            :key="user.id"
            xl="4"
            lg="6"
            md="4"
            sm="6"
          >
            <b-card
              no-body
              class="user-card"
              @click="$router.push({ name: 'admin-user-view', params: { id: user.id } })"
            >
              <div class="d-flex justify-content-between w-100">
                <div
                  class="d-flex"
                  style="flex-grow: 1"
                >
                  <b-avatar
                    rounded="left"
                    size="100px"
                    :src="`${user.image}/md`"
                    variant="light-primary"
                  />
                  <div
                    class="d-flex flex-column p-1"
                    style="flex-grow: 1"
                  >

                    <h5 class="mb-0">
                      {{ user.name }}
                    </h5>

                    <div class="user-account">
                      <div class="user-account-text">
                        {{ user.account }}
                      </div>
                    </div>

                    <div class="mt-50 text-nowrap">
                      <b-img
                        v-if="user.status"
                        v-b-tooltip.hover.focus.v-secondary
                        title="啟用"
                        rounded
                        fluid
                        class="user-state-icon"
                        src="/admin/images/users/stateOpen.svg"
                      />

                      <b-img
                        v-if="user.department_id"
                        v-b-tooltip.hover.focus.v-secondary
                        :title="user.department_info.name"
                        rounded
                        fluid
                        class="user-state-icon"
                        src="/admin/images/users/department.svg"
                      />
                    </div>
                  </div>
                </div>

                <div class="pr-50 py-50 d-flex justify-content-between flex-column">
                  <div>
                    <div
                      v-if="user.online_status === 'online'"
                      v-b-tooltip.hover.focus.v-secondary
                      title="在線"
                      class="state-dot dot-green"
                    />
                    <div
                      v-else-if="user.online_status === 'busy'"
                      v-b-tooltip.hover.focus.v-secondary
                      title="忙線"
                      class="state-dot dot-yellow"
                    />
                    <div
                      v-else
                      v-b-tooltip.hover.focus.v-secondary
                      title="離線"
                      class="state-dot dot-gray"
                    />
                  </div>

                  <div>
                    <feather-icon
                      :id="`online-${user.id}`"
                      icon="ClockIcon"
                      size="14"
                    />

                    <b-tooltip
                      :target="`online-${user.id}`"
                      boundary-padding="10"
                      boundary="window"
                      placement="right"
                    >
                      <table>
                        <tr>
                          <td class="text-right">
                            IP位置：
                          </td>
                          <td class="text-left">
                            <div class="table-title">
                              <div v-if="user.last_login_ip">
                                <div>{{ user.last_login_ip }}</div>
                              </div>

                              <div v-else>
                                <span>---</span>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-right">
                            最後操作：
                          </td>
                          <td class="text-left">
                            <div class="table-title">
                              <div v-if="user.last_login_ip">
                                <span
                                  v-b-tooltip.hover.focus.v-secondary
                                  :title="`${moment(user.last_action_time).format('HH:mm')}`"
                                >
                                  {{ updateOnline(user.last_action_time, refonlineTime) }}
                                </span>
                              </div>

                              <div v-else>
                                <span>---</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-show="tableData.length === 0"
          class="text-center my-5"
        >
          <b-img
            :src="$store.state.app.notFoundImg"
            fluid
            width="480"
            alt="查無資料"
          />
        </div>
      </div>

      <div
        v-else
        class="animate__animated animate__fadeIn"
      >
        <b-skeleton-wrapper :loading="true">
          <template #loading>
            <b-row>
              <b-col
                v-for="(user, index) in 12"
                :key="index"
                lg="4"
                md="6"
                sm="12"
              >
                <b-card no-body>
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded="left"
                        size="100px"
                        variant="light-secondary"
                      />
                    </b-media-aside>

                    <b-media-body class="mt-1">
                      <div>
                        <b-skeleton
                          animation="wave"
                          width="85%"
                        />
                        <b-skeleton
                          animation="wave"
                          width="55%"
                        />
                        <b-skeleton
                          animation="wave"
                          width="70%"
                        />
                      </div>
                    </b-media-body>
                  </b-media>
                </b-card>
              </b-col>

            </b-row>
          </template>
        </b-skeleton-wrapper>
      </div>
    </div>

    <!-- 顯示頁數/分頁 -->
    <div
      v-if="!isTableBusy"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- 顯示頁數 -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
        </b-col>

        <!-- 分頁 -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalNum"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div
            class="sidebar-shop"
            :class="{'show': mqShallShowLeftSidebar}"
          >
            <b-row>
              <b-col cols="12">
                <h6 class="d-none d-lg-block mb-1">
                  <!-- filter-heading  -->
                  篩選器
                </h6>
              </b-col>
            </b-row>

            <b-card
              no-body
              class="sidebar-shop-content"
            >
              <div class="sidebar-header d-lg-none d-block">
                篩選器
              </div>

              <div class="department-content py-1">
                <div class="department-header-title">
                  部門列表
                </div>

                <div
                  id="department-all"
                  class="structure-tree tree-item"
                >
                  <div
                    class="department"
                    :class=" {'department-active': searchDepartment === null}"
                  >
                    <div class="department-arrow">
                      <feather-icon icon="ChevronRightIcon" />
                    </div>
                    <div
                      class="department-title"
                      @click="searchDepartment = null"
                    >
                      全部 <span v-if="totalSetting.isSet">( {{ totalSetting.total.toLocaleString() }} )</span>
                    </div>
                  </div>
                </div>

                <div
                  v-for="department in departmentsOptions"
                  :id="`department-${department.id}`"
                  :key="department.id"
                  class="structure-tree tree-item"
                >
                  <div
                    class="department"
                    :class=" {'department-active': department.id === searchDepartment}"
                  >
                    <div class="department-arrow">
                      <feather-icon icon="ChevronRightIcon" />
                    </div>

                    <div
                      class="department-title"
                      @click="searchDepartment = department.id"
                    >
                      {{ department.name }} <span v-if="totalSetting.isSet">( {{ department.total.toLocaleString() }} )</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>

        <div
          class="body-content-overlay"
          :class="{'show': mqShallShowLeftSidebar}"
          @click="mqShallShowLeftSidebar = false"
        />
      </div>
    </portal>
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BAvatar, BTooltip, BCard, BRow, BCol, BPagination, VBTooltip, BImg, BSkeletonWrapper, BSkeleton,
  BMedia, BMediaBody, BMediaAside,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { ToggleButton } from 'vue-js-toggle-button'
// import flatPickr from 'vue-flatpickr-component'
// import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

// Component
import { useUserList, useUserSetting } from '../useUser'
import UpdateModal from './UsersListUpdateModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'
import { blankSocketData } from '@/libs/socket-client'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BTooltip,
    BCard,
    BSkeleton,
    BSkeletonWrapper,
    BRow,
    BCol,
    BImg,
    BPagination,
    ToggleButton,
    UpdateModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    isSocketInit() {
      return this.$store.state.app.isSocketInitialized
    },
    windowVisibilityState() {
      return this.$store.state.app.windowVisibilityState
    },
    tableColumnsFilter() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.select && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.searchable && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.searchable)
    },
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  watch: {
    isSocketInit(newValue) {
      if (newValue) {
        this.resetSocketConnect()
      }
    },
    windowVisibilityState(newValue) {
      if (newValue) {
        if (this.userData && this.userData.id) {
          const index = this.tableData.findIndex(item => item.id === this.userData.id)
          if (index !== -1) {
            const onlinetatusType = {
              hidden: 'busy',
              visible: 'online',
            }
            this.tableData[index].online_status = onlinetatusType[newValue]
          }
        }
      }
    },
  },
  mounted() {
    this.initSocketFunction()
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    const { blankUsers } = blankSocketData()
    window.web.users = { ...blankUsers }
  },
  methods: {
    moment,
    // (觸發)新增/編輯管理員
    onSubmitUpdate(item) {
      if (!item) {
        this.selected = {}
        setTimeout(() => { this.$refs.updateModal.getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.updateModal.getData() }, 200)
    },

    // (刪除)管理員
    onSubmitDelete(item) {
      // if (this.authAbility.user !== 'write') return
      this.useSwalAlertWarning('刪除使用者', `你確定要永久刪除使用者 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setUserDelete({
              user_id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '使用者已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)更新狀態
    // refreshUserOnlineStatus: (id, status) => {
    //   if (!id || !this.$refs.refDataListTable) return
    //   const tableData = this.$refs.refDataListTable.$data.localItems
    //   const index = tableData.findIndex(item => item.id === id)
    //   if (index !== -1) {
    //     tableData[index].online_status = status
    //   }
    // },

    // (初始化)Socket
    initSocketFunction() {
      const windowFunction = window.web
      windowFunction.users = {
        refreshUsersList: data => {
          this.getMetaListData()
          if (!data || !data.id) {
            this.refetchData()
            return
          }
          // const tableData = this.$refs.refDataListTable.$data.localItems
          const index = this.tableData.findIndex(item => item.id === data.id)
          if (index !== -1) {
            const resolve = this.syncObject(this.tableData[index], data)
            this.$set(this.tableData, index, resolve)
          }
        },
        refreshUserOnlineStatus: (id, status) => {
          if (!id) return
          const index = this.tableData.findIndex(item => item.id === id)
          if (index !== -1) {
            this.tableData[index].online_status = status

            const onlinetatusType = {
              online: 'visible',
              busy: 'hidden',
            }
            if (onlinetatusType[status]) store.commit('app/UPDATE_WINDOW_VISIBILITY_STATE', onlinetatusType[status])
          }
        },
      }
      if (this.isSocketInit) store.commit('app/SET_SOCKET_INIT', false)
    },

    // (重建)Socket連線
    resetSocketConnect() {
      store.commit('app/SET_SOCKET_INIT', false)
    },
  },
  setup() {
    // 註冊模組
    const USER_ADMIN_STORE_MODULE_NAME = 'admin-user'

    if (!store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.registerModule(USER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(USER_ADMIN_STORE_MODULE_NAME)
    })

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const {
      refDataListTable,
      tableData,
      isTableBusy,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchStatus,
      timeRange,
      searchDepartment,
      totalSetting,

      setUserDelete,
      getUserListData,
      getMetaListData,
      departmentsOptions,
    } = useUserList()

    const {
      ui,
      statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useUserSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    getUserListData()

    return {
      ui,
      useAlertToast,
      dateConfig2,
      statusOptions,
      refDataListTable,
      tableData,
      isTableBusy,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      getMetaListData,
      searchStatus,
      timeRange,
      departmentsOptions,
      searchDepartment,
      totalSetting,

      setUserDelete,
      getUserListData,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,

      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
$primary: #65d8ff;
.user-list {
  min-height: 65vh;
  .user-card:hover {
    cursor: pointer;
    outline: 1px solid #419fd9;
    transform: scale(1.05);
    box-shadow: rgba(70, 70, 70, 0.7) 1px 2px,
    rgba(70, 70, 70, 0.6) 2px 4px,
    rgba(70, 70, 70, 0.5) 3px 6px;

    // 要解决用户卡片被悬停时整个列表抖动的问题
    // border: 1px solid transparent;
    // box-sizing: border-box;
  }
  .user-account {
    padding: 10px;
    position: relative;
    .user-account-text {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .user-state-icon {
    max-width: 18px;
    margin-right: 10px;
  }
}
.sidebar-shop {
  z-index: 997;
  .sidebar-shop-content {
    .sidebar-header {
      padding: 0 20px;
      font-size: 18px;
      font-weight: bolder;
      margin: 20px 0 0 0;
    }
    .department-content {
      .department-header-title {
        padding: 0 15px;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .structure-tree {
        .department {
          // padding: 8px 0;
          display: flex;
          align-items: center;
          max-width: 300px;
          padding-right: 15px;

          .department-arrow {
            margin-right: 10px;
            margin-left: 20px;
            &:hover {
              cursor: pointer;
            }
          }

          .department-title {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 40px;
            line-height: 40px;
            width: 100%;
            // padding: 0 20px;
            &:hover {
              cursor: pointer;
              color: $primary;
            }
          }

          &:hover {
            // width: 100%;
            background-color: #adadad30;
          }
        }

        .department-active {
          color: #65d8ff;
          // background-color: red;
        }
      }
    }
  }
}
.show {
  max-height: 100vh;
  overflow-y: scroll;
  .sidebar-shop-content {
    min-height: 100vh;
  }
}
</style>
